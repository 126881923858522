<template>
	<page-container :page="page" :locale="locale" class="index">
		<heading-image v-if="page.headingImage">
			<hero-image type="fullscreen">
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p class="hero-title">
						<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
				</div>
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>

			<booking-form :booking-form="defaults[locale].bookingForm" />
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #full-content>
				<div class="columns column6">
					<div class="reverse-title">
						<h1>{{ page.title }}</h1>
						<h2 v-if="page.subtitle" class="subtitle">{{ page.subtitle }}</h2>
					</div>
					<slot name="extra-heading" />
				</div>
				<div class="columns column5">
					<div v-parse-links v-html="page.content" />
					<slot name="extra-content" />
				</div>
			</template>
			<template #bottom-content>
				<div class="intro-bg-image" />
			</template>
		</main-content>

		<promoblocks :items="page.promoblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
					</div>
				</div>
			</template>
		</promoblocks>

		<section v-if="defaults[locale].homepage.benefit.length" class="bookingbenefits">
			<div class="row">
				<div class="columns column4 align-left">
					<div class="inner-content">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionBenefitsSubtitle }}
						</h2>
						<h2>
							{{ defaults[locale].homepage.sectionBenefitsTitle }}
						</h2>
						<ul class="benefits">
							<li v-for="benefit in defaults[locale].homepage.benefit" :key="benefit.ID">
								<div v-parse-links v-html="benefit.content" />
							</li>
						</ul>
						<book-button class="button book-now cta">
							<span>{{ defaults[locale].website.book }}</span>
						</book-button>
					</div>
				</div>
			</div>
			<picture>
				<source :srcset="defaults[locale].homepage.sectionBenefitsImageWebp" type="image/webp" />
				<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
				<img
					class="bg-image"
					:src="defaults[locale].homepage.sectionBenefitsImage"
					:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<contentblocks :items="page.contentblocks">
			<template v-if="defaults[locale].homepage.sectionContentblocksTitle" #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 35%);
}

.intro {
	padding: 150px 0;
}

.reverse-title {
	max-width: 520px;

	h1 {
		max-width: 100%;
	}
}

.intro-bg-image {
	background: url('~/assets/images/intro-content-bg-image-white-mout.png') no-repeat top right;
	position: absolute;
	bottom: 0;
	right: 50%;
	width: 632px;
	top: 130px;
	background-size: 270px;
	z-index: 1;
}

.bookingbenefits {
	background: var(--primary-background-color);
	position: relative;
	overflow: hidden;
	padding: 150px 0 300px;

	h2 {
		font-family: var(--body-font-family);
	}

	.column4 {
		position: relative;
		z-index: 2;

		&::before {
			content: '';
			display: block;
			background: var(--secondary-background-color);
			inset: 40px 40px 0 20px;
			position: absolute;
			z-index: 1;
		}

		.inner-content {
			position: relative;
			z-index: 2;
			width: 100%;
			margin: 0 0 40px 40px;
			padding: 60px 40px;
			background: #fff;
		}

		ul {
			list-style-type: none;

			li {
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center;

				&::before {
					content: '';
					display: block;
					width: 30px;
					height: 30px;
					background: url('~/assets/images/list-icon-green.svg') no-repeat center center;
					background-size: 100%;
					margin: 0 15px 0 0;
				}

				div {
					width: calc(100% - 45px);
				}

				i,
				:deep(i) {
					font-size: 13px;
					font-style: normal;
				}
			}
		}
	}

	.bg-image {
		position: absolute;
		z-index: 1;
		inset: 0 0 150px 30vw;
		width: 70vw;
		height: calc(100% - 150px);
		object-fit: cover;
	}

	.book-now {
		margin: 30px auto 0;
	}

	svg {
		margin: 0 auto 20px;
		color: var(--cta-color);
		font-size: 40px;
	}
}

.blocks-header {
	.column12 {
		flex-flow: column wrap;
	}
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	.hero-title {
		color: #fff;
		font-size: 80px;
		font-family: AutheniaTextured, serif;
		font-weight: 400;
		margin: 0 0 20px;
		width: 100%;
		display: inline-block;
		line-height: 1;
		transform: rotate(-5deg);
		animation: fade-in 3s ease forwards;
		animation-delay: 0.5s;
		opacity: 0;
	}

	:deep(p) {
		font-size: 1.6em;
		font-weight: 300;
	}
}

@media (max-width: 1080px) {
	.intro-bg-image {
		width: 100%;
		right: 0;
		left: 0;
		background-position: center bottom;
		bottom: 25px;
	}
}

@media (max-width: 880px) {
	.intro {
		padding: 100px 0 475px;
	}

	.hero-content {
		.hero-title {
			font-size: 60px;
		}

		:deep(p) {
			font-size: 1.2em;
			padding: 0 10px;
		}
	}

	.bookingbenefits {
		padding: 75px 0;

		.column4 {
			width: 100%;
			margin-right: 0;

			.inner-content {
				width: calc(100% - 40px);
			}
		}

		.bg-image {
			width: 100%;
			position: relative;
			inset: auto;
			margin: -40px 0 40px;
			height: auto;
		}
	}
}
</style>
